//
// Form
//

.c-form {
    
    @include media-breakpoint-up(xl) {
        margin-right: 30%;
    }

    .form-group {
        margin-bottom: 40px;
    }

    input,
    textarea,
    select {
        width: 100%;
        padding: 20px;
        font-family: $font-family-serif;
        font-size: $font-size-base;
        font-weight: 300;
        border: 1px solid $picgray-lightest;
        background-color: #efefef;
        box-shadow: none;

        @include media-breakpoint-up(md) {
            font-size: $font-size-lg + .1;
        }

        @include media-breakpoint-up(xl) {
            font-size: $font-size-lg + .2;
        }
    }

    textarea {
        min-height: 200px;
        padding-top: ($spacer / 2.5);
        padding-bottom: ($spacer / 2.5);
        resize: vertical;
    }
    input:focus,
    textarea:focus,
    select:focus {
        border: 1px solid $pink;
        outline: 0;
    }
    label,
    legend {
        font-family: $font-family-sans-serif;
        font-size: $font-size-h3;
        display: block;
        margin-bottom: .5rem;
    }
    fieldset {
        border-width: 0;
        padding: 0;
    }
    input[type="checkbox"],
    input[type="radio"] {
        display: inline
    }
    label > .label-body {
        display: inline-block;
        font-weight: normal;
        margin-left: .5rem;
    }
}