//
// News
//

// Config

// Object

.news-trigger {
    display: inline-block;
    padding: 15px 20px;
    font-family: $font-family-sans-serif;
    font-size: $font-size-lg;
    font-weight: 600;
    color: #fff;
    background-color: rgba(48,52,57,0.4);
    cursor: pointer;
    @include transition;

    @include media-breakpoint-down(sm) {
        font-size: $font-size-base;
    }

    &:hover {
        background-color: $pink;
    }

    i {
        font-weight: 600;
    }
}

.c-news {
    display: none;
    position: relative;
    padding: 60px 0;
    background-color: darken($picgray, 5%);
    color: #fff;

    @include media-breakpoint-up(lg) {
        padding: 80px 0;
    }

    .section-head {
        margin-bottom: 20px;
        font-family: $font-family-sans-serif;
        font-size: $font-size-lg;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;

        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
        }
    }

    .entry {
        position: relative;
        padding: 20px 0;
        border-top: 1px solid $picgray;

        @include media-breakpoint-up(lg) {
            padding: 40px 0;
        }

        .details {
            font-style: italic;
            font-size: $font-size-sm;
            color: $picgray-light;
        }

        a.title {
            display: block;
            margin-bottom: 10px;
            font-family: $font-family-sans-serif;
            font-size: 2em;
            line-height: 0.95em;
            font-weight: 600;
            letter-spacing: -.02em;
            text-transform: uppercase;
            color: #fff;
            @include transition;

            @include media-breakpoint-up(sm) {
                font-size: 3em;
            }

            @include media-breakpoint-up(md) {
                font-size: 4em;
            }

            @include media-breakpoint-up(lg) {
                font-size: 5em;
            }

            @include media-breakpoint-up(xl) {
                font-size: 6em;
            }

            &:hover {
                color: $pink;
            }
        }

        .summary {
            margin: 0;
            font-family: $font-family-serif;
            font-size: $font-size-base;
            font-weight: 300;
            color: $picgray-light;
            clear: both;

            @include media-breakpoint-up(sm) {
            font-size: $font-size-lg;
            }

            @include media-breakpoint-up(md) {
                font-size: $font-size-lg + .1;
            }

            @include media-breakpoint-up(xl) {
                margin-right: 30%;
                font-size: $font-size-lg + .2;
            }
        }
    }

    a.news-button {
        position: relative;
        display: inline-block;
        margin: 30px 0 20px;
        padding: 20px 30px;
        font-family: $font-family-sans-serif;
        font-size: $font-size-lg;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        color: #fff;
        border: 4px solid #fff;
        @include transition;

        @include media-breakpoint-down(sm) {
            padding: 15px 30px;
            font-size: $font-size-base;
        }

        &:hover {
            border: 4px solid $pink;
        }

        i {
            padding-left: 10px;
            font-weight: 600;
        }

        &:hover i {
        }
    }
}