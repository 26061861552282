//
// Main
//

// Object

.c-brands {
    padding: 60px 0;
    background-color: #fff;
    overflow: hidden;
    text-align: center;

    @include media-breakpoint-up(lg) {
        padding: 100px 0;
    }

    .section-head {
        margin-top: 6em;
        margin-bottom: 20px;
        font-family: $font-family-sans-serif;
        font-size: $font-size-lg;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $pink;
        text-align: left;
    }

    .logo {
        margin-bottom: 0;
        padding: 0;
        display: block;

        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
        }

        img {
            width: 100%;
            opacity: 0.7;
            transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;
        }

        &:hover {

            img {
                opacity: 1;
                -moz-transform: scale(1.1);
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }
        }
    }
}