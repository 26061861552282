//
// Main
//

// Object

.c-work {
    position: relative;
    overflow: hidden;

    a.box {
        position: relative;
        padding: 0;
        margin: -0.02em;
        display: block;
        overflow: hidden;

        img {
            width: 100%;            
               transition: all .4s ease-in-out;
               -moz-transition: all .4s ease-in-out;
               -webkit-transition: all .4s ease-in-out;
        }

        &:hover {

            img {
                filter: brightness(80%);
                -moz-filter: rightness(80%);
            }
        }

        .image-over {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: 40px 40px;
            background-repeat: no-repeat;
               transition: all .4s ease-in-out;
               -moz-transition: all .4s ease-in-out;
               -webkit-transition: all .4s ease-in-out;

            &:hover {
                background-position: 0 0;

                @include media-breakpoint-only(md) {
                    background-position: 10px 10px;
                }
            }
        }

        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            text-align: left;
            overflow: hidden;
            opacity: 0;
               transition: all .4s ease-in-out;
               -moz-transition: all .4s ease-in-out;
               -webkit-transition: all .4s ease-in-out;

            .copy {
                padding: 20px;

                @include media-breakpoint-up(xxl) {
                    padding: 40px;
                }

                .client {
                    display: inline-block;
                    margin-bottom: 0;
                    font-family: $font-family-sans-serif;
                    font-size: $font-size-base;
                    font-weight: 600;
                    line-height: 1em;
                    color: #fff;

                    transition: all .4s ease-in-out;
                    -moz-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;

                    @include media-breakpoint-only(md) {
                        font-size: $font-size-sm;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: $font-size-lg;
                    }
                }

                .desc {
                    display: none;

                    @include media-breakpoint-up(xl) {
                        display: block;
                        font-family: $font-family-sans-serif;
                        font-size: $font-size-sm;
                        font-weight: $font-weight-light;
                        color: #fff;
                    }
                }
            }
        }

        &:hover {

            .overlay {
                height: 100%;
                display: block;
                opacity: 1;
            }
        }
    }
}