//
// Inline Slides
//

.c-inline-slides {
	margin-bottom: 40px;
	position: relative;
	color: #fff;
	@include pager();

	@include media-breakpoint-up(lg) {
		margin-bottom: 100px;
	}

	img {
		margin-bottom: 0 !important;
	}
}

.c-inline-slides-div {
	position: relative;
	color: #fff;
	overflow: hidden;
	@include pager();

	.slide {
		width: 100%;
	}
}