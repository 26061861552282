//
// Card
//

// Config
$card-padding: ($gutter / 2);
$card-padding-desktop: $gutter;
$card-background-color: #fff;
$card-header-background-color: $gray-lightest;

// Component
.c-card {
    position: relative;
    border-radius: $br-card;
    background-color: $card-background-color;
    margin-bottom: $card-padding;

    transform-style: preserve-3d;

    @include shadow(1);

    @include media-breakpoint-up(md) {
        margin-bottom: $card-padding-desktop;
    }

    // Corner button
    .corner-button {
        position: absolute;
        right: -15px;
        top: -15px;
        z-index: $zIndex-8;
    }

    // Card Header
    &__header {
        border-top-left-radius: $br-card;
        border-top-right-radius: $br-card;
        padding: ($card-padding / 1.25) $card-padding;
        background-color: $card-header-background-color;
        border-bottom: 1px solid $gray-lighter;

        @include media-breakpoint-up(md) {
            padding: ($card-padding-desktop / 2) $card-padding-desktop;
        }

        // Title
        h1, h2, h3, h4, h5, h6 {
            &.title {
                font-size: 1.25rem;
                margin: 0;
                padding: 0;
                line-height: 1.2;
            }
        }

        // Sub-title
        .caption {
            color: $gray-light;
            font-size: 0.875rem;
            margin: 0;
            padding: 0;
            line-height: 1.2;
        }

        //
        // Modifiers
        //

        .c-card--success & {
            color: #fff;
            background-color: $ui-success;
            border-bottom: 1px solid darken($ui-success, 10%);
        }

        .c-card--info & {
            color: #fff;
            background-color: $ui-info;
            border-bottom: 1px solid darken($ui-info, 10%);
        }

        .c-card--warning & {
            color: #fff;
            background-color: $ui-warning;
            border-bottom: 1px solid darken($ui-warning, 10%);
        }

        .c-card--danger & {
            color: #fff;
            background-color: $ui-danger;
            border-bottom: 1px solid darken($ui-danger, 10%);
        }
    }

    // Card Body
    &__body {
        padding: $card-padding;

        @include media-breakpoint-up(md) {
            padding: $card-padding-desktop;
        }
    }

    // Card Footer
    &__footer {
        padding: ($card-padding / 2) $card-padding;
        border-top: 1px solid $gray-lighter;

        @include media-breakpoint-up(md) {
            padding: $card-padding-desktop;
        }

        h1, h2, h3, h4, h5, h6, p, ul, ol {
            margin: 0;
            padding: 0;
            line-height: $line-height;
        }
    }

    //
    // States
    //

    // Inactive card
    &.is-inactive {
        border: 3px dashed darken($gray-lightest, 15%);
        background-color: $gray-lightest;
        box-shadow: none;

        .corner-button {
            right: -17px;
            top: -17px;
        }
    }

}
