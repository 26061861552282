//
// Text input
//

.c-textinput {
    width: 100%;
    height: auto;
    padding: $spacer / 2;

    border-radius: $br-input;
    border: 1px solid $gray-lighter;
    background-color: #fff;

    transition: background $animation-xshort ease-in-out, border-color $animation-xshort ease-in-out;

    &:hover,
    &:focus {
       background: lighten($gray-lighter, 10%);
    }

    &:focus {
        border: 1px solid $ui-primary;
    }

    &--small {
        height: 41px;
    }

    &--disabled {
        background-color: $gray-lightest;
    }

    &--invalid {
        border-color: $red;
    }

}
