//
// Main
//

// Config

// Object
.c-people {
    position: relative;
    overflow: hidden;
    background-color: $picgray-light;
    color: #fff;
    @include pager();

    .photo {
        padding: 0;

        img {
            width: 100%
        }
    }

    .fa-quote {
        font-size: $font-size-h3;
        margin-bottom: 10px;

        @include media-breakpoint-up(xl) {
            font-size: $font-size-h1;
        }
    }

    .quote {
        height: 300px;
        padding-right: 15%;
        font-family: $font-family-serif;
        font-weight: 300;
        font-size: $font-size-base;

        @include media-breakpoint-up(sm) {
            font-size: $font-size-lg;
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            right: 0;
            height: 100%;
            font-size: $font-size-base;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 40px;
            font-size: $font-size-lg;
        }

        @include media-breakpoint-up(xl) {
            font-size: $font-size-h4;
        }

        @include media-breakpoint-up(xxl) {
            font-size: $font-size-h2;
        }

        .name {
            font-family: $font-family-sans-serif;
            font-size: $font-size-sm;

            @include media-breakpoint-up(lg) {
                font-size: $font-size-base;
            }
        }
    }
}