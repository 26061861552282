//
// Typography
//

@import url($primary-font-url);
@import url($secondary-font-url);

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
}

h1, .h1,
h2, .h2,
h3, .h3 {
    margin-bottom: $headings-margin-bottom;
}

h4, .h4,
h5, .h5,
h6, .h6 {
    margin-bottom: $headings-margin-bottom;
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }

//
// Paragraph Lead
//

.lead {
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;
}

//
// Links
//

a {
    color: $link-color;
    text-decoration: $link-decoration;

    transition: color $animation-xshort ease-in-out;

    &:hover {
        color: darken($link-color, 15%);
        text-decoration: $link-hover-decoration;
    }

    &:focus {
        // Default
        outline: thin dotted;
        // WebKit
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
}

//
// Horizontal rules
//

hr {
    margin-top: $spacer-y;
    margin-bottom: $spacer-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
}

//
// Emphasis
//

small,
.small {
    font-size: 80%;
    font-weight: normal;
}

mark,
.mark {
    padding: .2em;
    background-color: $red;
}

//
// Lists
//

// Unstyled keeps list items block level, just removes default browser padding and list-style
.list-unstyled {
    padding-left: 0;
    list-style: none;
}

// Inline turns list items into inline-block
.list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;

    > li {
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
    }
}

// Horizontal description lists w/ grid classes
.dl-horizontal {
    margin-right: -$gutter;
    margin-left: -$gutter;
    @include clearfix;
}

//
// Misc
//

// Builds on `abbr`
.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

// Blockquotes
.blockquote,
blockquote {
    margin: 1.875rem 0;
    padding: 1.875rem;
    text-align: center;
    border-top: .0625rem solid $gray-lighter;
    border-bottom: .0625rem solid $gray-lighter;

    p {
        font-size: 22px;
        color: $gray;
        font-style: italic;
    }

    p,
    ul,
    ol {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .by {
        font-size: 0.875rem;
    }
}
