//
// Main
//

// Object

.c-interior-hero {
    padding: 180px 0 60px;
    position: relative;

    .container {
        position: relative;
    }

    .copy-block {
        color: $picgray;
        text-align: left;

        & h1 {
            margin-left: -3px;
            margin-bottom: 20px;
            font-family: $font-family-sans-serif;
            font-size: 3em;
            font-weight: 700;
            line-height: 0.95em;
            letter-spacing: -.04em;
            text-transform: uppercase;
            // text-shadow: 2px 2px 12px rgba(51, 51, 51, .5);

            @include media-breakpoint-up(sm) {
                font-size: 4em;
            }

            @include media-breakpoint-up(md) {
                font-size: 5em;
            }

            @include media-breakpoint-up(lg) {
                font-size: 6em;
            }

            @include media-breakpoint-up(xl) {
                font-size: 8em;
            }
        }
    }

    & p {
        font-family: $font-family-serif;
        font-size: $font-size-base;
        font-weight: 300;

        @include media-breakpoint-up(sm) {
        font-size: $font-size-lg;
        }

        @include media-breakpoint-up(md) {
            font-size: $font-size-lg + .1;
        }

        @include media-breakpoint-up(xl) {
            margin-right: 30%;
            font-size: $font-size-lg + .2;
        }
    }

    .small {
        font-family: $font-family-sans-serif;
        font-size: $font-size-sm;
        text-transform: uppercase;
        letter-spacing: .1em;
        font-weight: 600;
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
        }
    }
}