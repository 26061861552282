//
// Tiles
//

// Config
$tile-padding: 10px;

// Mixin
@mixin build($columns, $padding: false) {
    li {
        width: percentage(1 / $columns);

        @if($padding) {
            padding: 0 ($padding / 2) $padding ($padding / 2);

            &:first-child {
                padding-left: 0;
            }

            &:nth-child(#{$columns}) {
                padding-right: 0;
            }
        }
    }
}

// Component
.c-tiles {
    @include reset-list;
    overflow: hidden;

    li {
        display: block;
        float: left;
        margin-bottom: 0;

        // Tile
        .tile {
            display: block;
            position: relative;
            z-index: $zIndex-1;

            // With overlay
            .overlay {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;

                background-color: rgba(0, 0, 0, .6);
                opacity: 0;
                transition: all .5s ease .05s;

                color: #fff;
                text-decoration: none;
                text-align: center;

                z-index: $zIndex-2;

                // Reset text
                h1, h2, h3, h4, h5, h6, p, span, ul, ol {
                    margin: 0;
                    padding: 0;
                }

                // Content displayed in overlay
                .overlay-content {
                    @include valign;

                    // Icons
                    [class^='icon-'] {
                        font-size: 6rem;
                    }
                }
            }

            // Tile (with overlay) hover
            &.active,
            &:hover {
                .overlay {
                    opacity: 1;
                    * {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &--halves {
        @include build(2);
    }

    &--thirds {
        @include build(3);
    }

    &--quarters {
        @include build(4);
    }

    &--fifths {
        @include build(5);
    }

    &--sixths {
        @include build(6);
    }

    // Padded
    &--halves-padded {
        @include build(2, $tile-padding);
    }

    &--thirds-padded {
        @include build(3, $tile-padding);
    }

    &--quarters-padded {
        @include build(4, $tile-padding);
    }

    &--fifths-padded {
        @include build(5, $tile-padding);
    }

    &--sixths-padded {
        @include build(6, $tile-padding);
    }
}
