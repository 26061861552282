//
// Content
//

// Object

.c-content {
    padding: 60px 0;

    @include media-breakpoint-up(lg) {
        padding: 120px 0;
    }

    h1,h2,h3,h4 {
        color: #000;
        display: inline-block;
    }

    h2,h3,h4 {
        margin-top: $spacer + 2;
        margin-bottom: $spacer;
    }

    p {
        font-family: $font-family-serif;
        font-size: $font-size-base;
        font-weight: 300;

        @include media-breakpoint-up(md) {
            font-size: $font-size-lg + .1;
        }
        
        @include media-breakpoint-up(xl) {
            margin-right: 30%;
        }
    }
}
