//
// Button
//

// Component
.c-button {
    position: relative;
    display: inline-block;
    margin: 0 0 40px;
    padding: 20px 30px;
    font-family: $font-family-sans-serif;
    font-size: $font-size-lg;
    font-weight: 600;
    color: $picgray-light;
    border: 4px solid $picgray-light;
    @include transition;

    @include media-breakpoint-down(sm) {
        padding: 15px 20px;
        font-size: $font-size-base;
    }

    &:hover {
        border: 4px solid $pink;
        color: $picgray-light;
    }

    i {
        padding-left: 10px;
        font-weight: 700;
    }
}