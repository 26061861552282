//
// Shadow
//
// A mixin which helps you to add depth to elements according to the Google Material Design spec:
// http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality
//
// Please note that the values given in the specification cannot be used as is. To create the same visual experience
// the blur parameter has to be doubled.
//
// Author: Florian Kutschera (@gefangenimnetz), Conceptboard GmbH (@conceptboardapp)
//
// Example usage:
//
// .card {
//     width: 95px;
//     height: 95px;
//     background: #f4f4f4;
//     -webkit-transition: all 250ms;
//     -moz-transition: all 250ms;
//     transition: all 250ms;
//     .shadow(1);
//     &:hover {
//         .shadow(3);
//         -webkit-transform: translateY(-5px);
//         -moz-transform: translateY(-5px);
//         transform: translateY(-5px);
//     }
// }
//
//

@mixin shadow($level: 1){
    @if $level == 1 {
        box-shadow: 0 1px 3px rgba(0, 0, 0, .02), 0 1px 2px rgba(0, 0, 0, 0.05);
    }
    @if $level == 2 {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.08);
    }
    @if $level == 3 {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.2);
    }
    @if $level == 4 {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
    @if $level == 5 {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.2), 0 15px 12px rgba(0, 0, 0, 0.24);
    }
}
