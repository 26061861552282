//
// Label
//

.c-label {
    display: block;
    margin-bottom: $gutter;

    font-size: $font-size-sm;
    color: $gray-light;
}
