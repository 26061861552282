//
// Main
//

// Object

.c-hero {
    height: 100%;
    @include pager();

    .cycle-slideshow {
        height: 100%;

        @include media-breakpoint-up(lg) {
            min-height: 750px;
        }

        .slide {
            position: relative;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;

            .container {
                position: relative;
            }

            .copy-block {
                position: absolute;
                bottom: 80px;
                width: 100%;
                color: #fff;
                text-align: left;
                // background-color: rgba(43,49,56,0.6);

                @include media-breakpoint-up(sm) {
                    margin-top: -4%;
                }

                & h1 {
                    margin-left: -3px;
                    margin-bottom: 20px;
                    font-family: $font-family-sans-serif;
                    font-size: 3em;
                    font-weight: 600;
                    line-height: 0.95em;
                    letter-spacing: -.02em;
                    text-transform: uppercase;
                    // text-shadow: 2px 2px 12px rgba(51, 51, 51, .5);

                    @include media-breakpoint-up(sm) {
                        font-size: 4em;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 5em;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 6em;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: 8em;
                    }
                }
            }

            & p {
                margin-right: 60px;
                margin-bottom: 60px;
                font-family: $font-family-serif;
                font-size: $font-size-base;
                font-weight: 300;

                @include media-breakpoint-up(sm) {
                font-size: $font-size-lg;
                }

                @include media-breakpoint-up(md) {
                    font-size: $font-size-lg + .1;
                }

                @include media-breakpoint-up(xl) {
                    margin-right: 30%;
                    font-size: $font-size-lg + .2;
                }
            }

            .small {
                margin-bottom: 20px;
                font-family: $font-family-sans-serif;
                font-size: $font-size-sm;
                text-transform: uppercase;
                letter-spacing: .1em;
                font-weight: 600;

                @include media-breakpoint-up(lg) {
                    font-size: $font-size-base;
                }
            }

            .hide {
                display: none;

                @include media-breakpoint-up(sm) {
                    display: block;
                }
            }

            a.hero-button {
                position: relative;
                display: inline-block;
                padding: 20px 30px;
                font-family: $font-family-sans-serif;
                font-size: $font-size-lg;
                font-weight: 600;
                color: #fff;
                border: 4px solid #fff;
                @include transition;

                @include media-breakpoint-down(sm) {
                    padding: 15px 20px;
                    font-size: $font-size-base;
                }

                &:hover {
                    border: 4px solid $pink;
                }

                i {
                    padding-left: 10px;
                    font-weight: 700;
                }

                &:hover i {
                }
            }
        }
    }
}