//
// Table
//

// Config
$table-cell-padding: .75rem;
$table-sm-cell-padding: .3rem;

$table-bg: transparent;
$table-bg-accent: #f9f9f9;
$table-bg-hover: #f5f5f5;
$table-bg-active: $table-bg-hover;

$table-border-color: $gray-lighter;

.c-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: $spacer;

    font-family: $font-family-sans-serif;
    font-size: 14px;
    color: $gray-darker;

    table-layout: fixed;

    th,
    td {
        padding: $table-cell-padding;
        line-height: $line-height;
        vertical-align: top;
        border-top: 1px solid $table-border-color;

        word-wrap: break-word;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: 2px solid $table-border-color;

        h1, h2, h3, h4, h5 {
            font-family: $font-family-sans-serif;
            font-size: 1.5rem;
            font-weight: $font-weight-bold;
            margin: 0;
        }
    }

    tbody + tbody {
        border-top: 2px solid $table-border-color;
    }

    .table {
        background-color: $body-bg;
    }

    &--bordered {
        border: 1px solid $table-border-color;

        th,
        td {
            border: 1px solid $table-border-color;
        }

        thead {
          th,
          td {
            border-bottom-width: 2px;
          }
        }
    }

    &--striped {
        tbody tr:nth-of-type(odd) {
            background-color: $table-bg-accent;
        }
    }

    &--hover {
        tbody tr {
            &:hover {
                background-color: $table-bg-hover;
            }
        }
    }

    &--compact {
        th,
        td {
            padding: $table-sm-cell-padding;
        }
    }
}
