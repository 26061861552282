//
// Main
//

// Object
.o-footer {
    position: relative;
    background-color: $picgray;
    padding: 60px 0;
    color: #fff;

    @include media-breakpoint-up(lg) {
        padding: 80px 0;
    }

    .logo {

        img {
            width: 50px;
            margin-bottom: 20px;
        }
    }

    .col {
        display: block;
        width: 100%;
        float: left;
        margin-right: 30px;
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
            display: inline-block;
            width: auto;
            margin-right: 40px;
        }

        @include media-breakpoint-up(xl) {
            margin-right: 60px;
        }

        .title {
            font-family: $font-family-sans-serif;
            font-size: $font-size-base;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 600;
        }

        p {
            font-family: $font-family-serif;
            font-size: $font-size-base;
            font-weight: 300;
            line-height: $line-height + .2;
        }

        span {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }

    .social {
        @include transition;

        a {
            font-size: 40px;
            margin-top: -5px;
            margin-right: 10px;
            color: $picgray-light;

            @include media-breakpoint-up(md) {
                font-size: 30px;
            }

            @include media-breakpoint-up(xl) {
                font-size: 40px;
            }

            &:hover {
                color: #fff;
            }
        }
    }

    .tag {

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            right: 10px;
        }

        p {
            font-family: $font-family-sans-serif;
            font-size: 30px;
            font-weight: 600;
            line-height: 1em;
            text-transform: uppercase;

            @include media-breakpoint-up(md) {
                font-size: 20px;
            }

            @include media-breakpoint-up(xl) {
                font-size: 30px;
            }
        }
    }
}
