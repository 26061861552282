//
// Main
//

// Object

.c-interior-work {
    position: relative;
    overflow: hidden;

    .section-head {
        margin-top: 6em;
        margin-bottom: 20px;
        font-family: $font-family-sans-serif;
        font-size: $font-size-lg;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $pink;
    }
    
    .box {
        margin: -.02rem;
        padding: 0;
        display: block;
        position: relative;
        overflow: hidden;
        // background-color: $picgray;

        img {
            width: 100%;
            transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;

            -webkit-backface-visibility: hidden;
            -ms-transform: translateZ(0); /* IE 9 */
            -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
            transform: translateZ(0);
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(48,52,57,0.8);
            z-index: 100;
            text-align: center;
            overflow: hidden;
            opacity: 0;
               transition: all .3s ease-in-out;
               -moz-transition: all .3s ease-in-out;
               -webkit-transition: all .3s ease-in-out;

            .copy {
                @include valign();
                padding: 20px;

                .client {
                    display: inline-block;
                    padding-bottom: 5px;
                    margin-bottom: 15px;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: #fff;
                    border-bottom: 2px solid #fff;

                    transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -webkit-transition: all .3s ease-in-out;

                    @include media-breakpoint-up(md) {
                        font-size: $font-size-sm;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: $font-size-base;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: $font-size-lg;
                    }
                }

                .desc {
                    display: block;
                    margin-bottom: 0;
                    color: #fff;

                    @include media-breakpoint-up(sm) {
                        font-size: $font-size-sm;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: $font-size-base;
                    }
                }
            }
        }
    }
}